<template>
    <div>
        <div>
            <Breadcrumbs :data="menus"/>
        </div>
        <div>
            <form class="pure-form pure-form-stacked">
                <fieldset>
                    <legend></legend>
                    <div class="pure-g">
                        <div class="pure-u-1-6">
                            <label for="multi-last-name"></label>
                            <input type="date" id="multi-last-name" class="pure-u-23-24" v-model="dateRange[1]"/>
                        </div>
                        <div class="pure-u-1-6">
                            <label for="multi-state"></label>
                            <select id="multi-state" class="pure-u-23-24" v-model="tableFilter.tz">
                                <option v-for="(val, idx) in options.tzs" :key="idx" :value="tzi(val.offset)">
                                    {{ val.label }}
                                </option>
                            </select>
                        </div>
                        <div class="pure-u-1-8">
                            <label for="shortcode"></label>
                            <input type="text" id="shortcode" class="pure-u-23-24" v-model="tableFilter.shortcode"
                                   placeholder="shortcode"/>
                        </div>
                        <div class="pure-u-1-8">
                            <label for="keyword"></label>
                            <input type="text" id="keyword" class="pure-u-23-24" v-model="tableFilter.keyword"
                                   placeholder="keyword"/>
                        </div>
                        <div class="pure-u-1-4">
                            <div style="padding: 0.3em;">
                                <button type="submit" class="pure-button pure-button-primary"
                                        style="margin-right: 0.4em;" @click="refreshData();">Query
                                </button>
                                <button class="pure-button" style="margin-right: 0.4em;" @click="reset()">Reset</button>
                                <button type="submit" class="pure-button" @click="doExport()">Export</button>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
        <div>
            <h4>Total: {{pagination.total}}</h4>
        </div>
        <div>
            <table class="pure-table pure-table-bordered" style="width: 100%;font-size: 14px;">
                <thead>
                <tr>
                    <th>Date</th>
                    <th>TrxID</th>
                    <th>Country</th>
                    <th>Operator</th>
                    <th>Shortcode</th>
                    <th>Keyword</th>
                    <th>MSISDN</th>
                    <th>SMS</th>
                </tr>
                </thead>
                <tr v-if="loading">
                    <td style="padding: 3em; text-align: center;" colspan="8" >
                        <img src="../../assets/image/loading.svg" alt="loading" style="width: 30px;"/>
                    </td>
                </tr>
                <tr v-if="!loading && tableData.length === 0">
                    <td style="padding: 3em; text-align: center;" colspan="8" >
                        EMPTY DATA
                    </td>
                </tr>
                <tbody v-if="!loading">
                <tr v-for="(val,idx) in tableData" :key="idx">
                    <td>{{ val.ct_date }}</td>
                    <td>{{ val.mo_id }}</td>
                    <td>{{ val.country }}</td>
                    <td>{{ val.operator }}</td>
                    <td>{{ val.shortcode }}</td>
                    <td>{{ val.keyword }}</td>
                    <td>{{ val.msisdn }}</td>
                    <td>{{ val.sms }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <Pagination @gotoPage="gotoPage"
                    :size="this.pagination.size"
                    :total="this.pagination.total"
                    :page="this.pagination.page"/>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import {BrowserDefaultTimezone, MinimalTimezoneSet, TimezoneNumberOffset} from "@/utils/tz";
import {ToApiDate} from "@/utils/time";
import {CpDashCodeReportExport, CpDashCodeReportRead} from "@/store/api/report_mo";

const defaultEnd = new Date();
const defaultStart = new Date();
defaultStart.setTime(defaultStart.getTime() - 3600 * 1000 * 24);
const defaultTz = BrowserDefaultTimezone()
import Pagination from "@/components/Pagination";

export default {
    name: "TransactionOtp",
    components: {Pagination, Breadcrumbs},
    data() {
        return {
            menus: [{label: 'Dash', to: '/cpdash/dash'}, {label: 'OneTimePaymentTransactions', to: ''}],
            options: {tzs: MinimalTimezoneSet},
            tableFilter: {
                date: '',
                tz: defaultTz,
                shortcode: '',
                keyword: '',
            },
            pagination: {total: 0, size: 20, page: 1},
            loading: true,
            tableData: [],
            dateRange: [0, ToApiDate(defaultTz, defaultEnd)],
        }
    },
    mounted() {
        console.log(this.dateRange);
        this.refreshData();
    },
    methods: {
        tzi(val) {
            return TimezoneNumberOffset(val)
        },
        dump() {
            console.log(this.dateRange);
        },
        formatDate: function (ts) {
            let d = new Date(ts * 1000 + this.tableFilter.tz * 3600 * 1000).toISOString();
            let f = d.replaceAll('T', ' ').replaceAll('Z', '')
            return f.substr(0, f.length - 4)
        },
        gotoPage(val) {
            this.pagination.page = val;
            console.log('goto.page',val)
            this.refreshData();
        },
        reset() {
            this.tableFilter.shortcode = '';
            this.tableFilter.keyword = '';
            this.tableFilter.date = ToApiDate(defaultTz, defaultStart);
            this.refreshData();
        },
        doExport: function () {
            this.loading = true;
            this.tableFilter.date = this.dateRange[1];
            CpDashCodeReportExport(this.filterForm).then((resp) => {
                window.location.href = resp.data.data.link;
            }).finally(() => {
                this.loading = false;
            });
        },
        refreshData() {
            this.loading = true;
            this.tableData = [];
            this.tableFilter.date = this.dateRange[1];
            this.tableFilter.page = this.pagination.page*1;
            this.tableFilter.size = this.pagination.size;
            CpDashCodeReportRead(this.tableFilter).then((resp) => {
                if (resp.data.data.count === 0) {
                    this.tableData = [];
                } else {
                    this.tableData = resp.data.data.data;
                    this.pagination.total = resp.data.data.count;
                }
                console.log('....', this.tableData)
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>

<style scoped lang="scss">
.filter {
    margin-top: 1em;
}

.table-main {
    margin: 1em 0;
}

.pure-form input[type=date] {
    padding: 0.34em 0.6em;
}
</style>